import * as React from 'react';
import cn from 'classnames';
import Grid from 'styleguide/components/Grid/Grid';
import css from './GenericPeopleSection.scss';
import H2 from 'styleguide/components/Heading/H2';
import Person from './Person';

interface List {
  name: string;
  imageUrl: string;
  role: string;
  joined: number;
  tagLine: string;
}

interface Props {
  peopleList: List[];
  description?: string;
  PeopleContainerClass?: string;
  peopleTitleClass?: string;
  peopleDescriptionClass?: string;
}

const GenericPeopleSection = ({
  PeopleContainerClass,
  peopleList,
  description,
  peopleTitleClass,
  peopleDescriptionClass,
}: Props) => (
  <Grid.Container className={cn(css.container, PeopleContainerClass)}>
    <Grid>
      <Grid.Row className={cn(css.row)}>
        <Grid.Col sm={12} md={5}>
          <div className={css.headingContainer}>
            <H2 className={cn(css.peopleTitle, peopleTitleClass)}>Our People</H2>
            <p className={cn(css.description, peopleDescriptionClass)}>{description}</p>
          </div>
        </Grid.Col>
        <Grid.Col sm={12} md={7}>
          {peopleList.map((individual, index) => (
            <Person {...individual} imageClassName={cn([css[`person--${index % 3}`]])} />
          ))}
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default GenericPeopleSection;
