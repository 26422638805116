import * as React from 'react';
import cn from 'classnames';
import css from './CareerHero.scss';
import Grid from 'styleguide/components/Grid/Grid';
import H1 from 'styleguide/components/Heading/H1';
import Span from 'styleguide/components/Span/Span';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';

import heroImage from 'assets/images/CareerPage/betterment-group-wide.jpg';

const CareerHero = () => (
  <Grid.Container>
    <Grid fullWidth>
      <Grid.Row className={cn(css.heroTextContainer)}>
        <Grid.Col sm={12} className={cn(css.container, css.heroText)}>
          <H1 className={cn(css.heroText, css.heroHeading)}>Betterment</H1>
          <Span className={cn(css.largeText, css.bold)}>Believe.</Span>
          <Span className={cn(css.normalText)}>
            No matter where you come from, or who you are now, you can always become a better version of
            yourself.
          </Span>
          <Span className={cn(css.normalText)}>
            <p>
              If that’s the journey you’re on, know that this is a place where goals are set and met.
              <br />
              Careers grow, habits develop, and experiences multiply.
            </p>
          </Span>
          <Span className={cn(css.normalText)}>How? Well, the environment you’re in matters.</Span>
          <ButtonAsNativeLink
            dataCy="join-us-btn"
            buttonType="anchor"
            target="#joinus"
            color="orange"
            className={cn(css.heroButton)}
          >
            Join Us
          </ButtonAsNativeLink>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col className={css.heroBackground} style={{ backgroundImage: `url('${heroImage}')` }} />
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default CareerHero;
