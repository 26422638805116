import * as React from 'react';
import cn from 'classnames';
import Grid from 'styleguide/components/Grid/Grid';
import Image from 'styleguide/components/Image/Image';
import css from './GrowthSection.scss';
import H2 from 'styleguide/components/Heading/H2';

import inc5000 from 'assets/images/CareerPage/inc-5000.png';

const GrowthSection = () => (
  <Grid.Container className={css.growthContainer}>
    <Grid>
      <Grid.Row>
        <Grid.Col sm={12} md={6} className={cn(css.iconContainer, css.tabletMobileSecond)}>
          <Image
            url={inc5000}
            title="Inc. 5000"
            alt="Printivity one of the fastest growing companies according to Inc. 5000"
            responsive
            className={cn(css.image)}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6} className={cn(css.headingContainer, css.tabletMobileFirst)}>
          <H2 className={css.heading}>Our Growth</H2>
          <p className={cn(css.subtext)}>
            3X winner of the prestigious Inc. 5000 award for &quot;Fastest Growing Companies In America&quot;.
          </p>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </Grid.Container>
);
export default GrowthSection;
