import * as React from 'react';
import css from './PerkSection.scss';
import GenericPerkSection from 'styleguide/components/GenericPerkSection/GenericPerkSection';

const CareerPerks = [
  { perkTitle: 'Free Health Insurance', perkDescription: 'We believe in coverage for everyone on our team.' },
  {
    perkTitle: 'Free College Program',
    perkDescription: 'We believe in education being a great equalizer.',
  },
  {
    perkTitle: 'Raises Every 6 months',
    perkDescription: 'We believe in recognizing hard work and improvement.',
  },
  { perkTitle: 'Paid Time Off', perkDescription: 'We believe in balancing the complexities of life.' },
  { perkTitle: 'Paid Volunteer Time', perkDescription: 'We believe in making our communities better.' },
  {
    perkTitle: 'Employee Discounts',
    perkDescription: 'We believe in the pursuit of creative and entrepreneurial endeavors.',
  },
];
const PerkSection = () => (
  <GenericPerkSection
    title={<>Our Perks</>}
    description={<>Curated benefits for people who place personal and community advancement as a priority.</>}
    perks={CareerPerks}
    perkBlockClass={css.perkBlock}
    perkTitleClass={css.perkTitle}
    perkDescriptionText={css.perkDescriptionText}
    perkContainerClass={css.perkContainer}
    perkRowClass={css.perkRow}
    perkTaglineClass={css.perkTagline}
    perkHeadingClass={css.perkHeading}
    perkHeadingContainer={css.perkHeadingContainer}
    perksBlockContainer={css.perksBlockContainer}
  />
);
export default PerkSection;
