import * as React from 'react';
import cn from 'classnames';
import Grid from 'styleguide/components/Grid/Grid';
import css from './GenericPerkSection.scss';
import H2 from 'styleguide/components/Heading/H2';

interface Perks {
  perkTitle: string;
  perkDescription: string;
}

interface Props {
  perks?: Perks[];
  title?: React.ReactNode;
  description?: React.ReactNode;
  perkBlockClass?: string;
  perkTitleClass?: string;
  perkDescriptionText?: string;
  perkContainerClass?: string;
  perkRowClass?: string;
  perkTaglineClass?: string;
  perkHeadingClass?: string;
  perkHeadingContainer?: string;
  perksBlockContainer?: string;
}

const GenericPerkSection = ({
  perkContainerClass,
  perkRowClass,
  title,
  perkTaglineClass,
  description,
  perks,
  perkDescriptionText,
  perkTitleClass,
  perkBlockClass,
}: Props) => (
  <Grid.Container className={cn(css.container, perkContainerClass)}>
    <Grid>
      <Grid.Row className={cn(css.row, perkRowClass)}>
        <Grid.Col sm={12} md={4}>
          <div className={cn(css.headingContainer)}>
            <H2 className={cn(css.heading)}>{title}</H2>
            <p className={cn(css.tagLine, perkTaglineClass)}>{description}</p>
          </div>
        </Grid.Col>
        <Grid.Col sm={12} md={8}>
          <div className={cn(css.perksContainer)}>
            {perks.map((perk, index) => (
              <div key={`perk_${index}`} className={cn(css.perkBlock, perkBlockClass)}>
                <p className={cn(css.perkText, perkTitleClass)}>{perk.perkTitle}</p>
                <p className={cn(perkDescriptionText)}>{perk.perkDescription}</p>
              </div>
            ))}
          </div>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </Grid.Container>
);
export default GenericPerkSection;
