import * as React from 'react';
import GenericPeopleSection from 'styleguide/components/GenericPeopleSection/GenericPeopleSection';

import erikHeadshot from 'assets/images/CareerPage/erik-headshot.png';
import thomasHeadshot from 'assets/images/CareerPage/thomas-headshot.png';
import kimHeadshot from 'assets/images/CareerPage/kim-headshot.png';

const PeopleList = [
  {
    name: 'Erik',
    imageUrl: erikHeadshot,
    role: 'Chief Technology Officer',
    joined: 2014,
    tagLine:
      'Making yourself a little bit better every single day is a goal that I can get behind. We’re always making progress.',
  },
  {
    name: 'Thomas',
    imageUrl: thomasHeadshot,
    role: 'Bindery Operator',
    joined: 2018,
    tagLine:
      'Printivity offers me the opportunity to learn new skills, earn a college degree, and volunteer to help our community. They are invested in my growth at all levels.',
  },
  {
    name: 'Kim',
    role: 'Prepress Technician',
    imageUrl: kimHeadshot,
    joined: 2016,
    tagLine:
      'There’s something special about being part of a team that shares a common goal of constant improvement. It’s motivating and makes me feel like I’m always becoming a better version of myself.',
  },
];

const PeopleSection = () => (
  <GenericPeopleSection
    peopleList={PeopleList}
    description="We are the caretakers of our culture. Creating an environment where betterment is encouraged is our charter. "
  />
);
export default PeopleSection;
