import * as React from 'react';
import cn from 'classnames';
import Image from 'styleguide/components/Image/Image';
import css from './Person.scss';
import H3 from 'styleguide/components/Heading/H3';

const Person = ({
  name = '',
  role = '',
  joined = null,
  tagLine = '',
  imageUrl = '',
  imageClassName,
  ...props
}) => (
  <div className={cn(css.container)} {...props}>
    <Image
      title={`${name}-${role}`}
      alt={`${name}-${role}`}
      url={imageUrl}
      className={cn(css.imageContainer, imageClassName)}
    />
    <p className={cn(css.taglineText)}>{`“${tagLine}“`}</p>
    <H3 className={cn(css.nameText)}>{name}</H3>
    <p className={cn(css.text)}>{role}</p>
    <p className={cn(css.text)}>{`Printivity since ${joined}`}</p>
  </div>
);

export default Person;
