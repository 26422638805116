import * as React from 'react';
import H2 from 'styleguide/components/Heading/H2';
import H5 from 'styleguide/components/Heading/H5';
import Grid from 'styleguide/components/Grid/Grid';
import cn from 'classnames';
import css from './TeamSection.scss';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import A from 'styleguide/components/Links/A';
import IfDesktop from 'styleguide/components/Conditional/IfDesktop';
import Span from 'styleguide/components/Span/Span';
import IfTabletOrMobile from 'styleguide/components/Conditional/IfTabletOrMobile';

const Team = ({ team, teamDescription }) => (
  <div className={team ? cn(css.teamBlock) : cn(css.nullBlock)}>
    <H5 className={css.teamName}>{team}</H5>
    <p className={css.teamDescription}>{teamDescription}</p>
  </div>
);

const CallToAction = () => (
  <div className={css.callToAction}>
    <ButtonAsNativeLink
      buttonType="anchor"
      color="orange"
      target="https://printivitysd.workable.com/"
      className={cn(css.button, css.topButton)}
    >
      View San Diego, CA Openings
    </ButtonAsNativeLink>
    <ButtonAsNativeLink
      buttonType="anchor"
      color="orange"
      target="https://printivitynj.workable.com/"
      className={css.button}
    >
      View Lyndhurst, NJ Openings
    </ButtonAsNativeLink>
    <p className={cn(css.tagline)}>Believe in betterment.</p>
  </div>
);

const TeamSection = () => (
  <Grid.Container className={cn(css.container)}>
    <Grid>
      <Grid.Row>
        <Grid.Col sm={12} md={12} lg={4}>
          <A className={css.anchorOffset} href="#" color="none" underline="none" name="joinus" />
          <Span className={cn(css.titleContainer)}>
            <H2 className={cn(css.title)}>Our Teams</H2>
            <IfDesktop>
              <CallToAction />
            </IfDesktop>
          </Span>
        </Grid.Col>
        <Grid.Col sm={12} md={12} lg={7} className={css.teamBlockWrapper}>
          <div className={cn(css.teamBlockContainer)}>
            <Team
              team="Digital Print Production"
              teamDescription="Operation of digital production print equipment, color matching, and quality control."
            />
            <Team
              team="Customer Service"
              teamDescription="Solutions and consultative driven approach for products and services."
            />
            <Team
              team="Bindery Production"
              teamDescription="Operation of post-press equipment, warehousing, and shipping responsibilities. "
            />
            <Team
              team="Digital Prepress"
              teamDescription="Digital file check, order setup, design, and queue management."
            />
            <Team
              team="Digital Marketing"
              teamDescription="Online demand generation management and implementation."
            />
            <Team
              team="Human Resources"
              teamDescription="Benefits, administration, and internal programs development."
            />
          </div>
        </Grid.Col>
      </Grid.Row>
      <IfTabletOrMobile>
        <Grid.Row>
          <Grid.Col sm={12} md={12} lg={4}>
            <CallToAction />
          </Grid.Col>
          <Grid.Col hiddenSm="down" hiddenMd="down" lg={7} className={css.teamBlockWrapper} />
        </Grid.Row>
      </IfTabletOrMobile>
    </Grid>
  </Grid.Container>
);

export default TeamSection;
