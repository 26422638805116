import * as React from 'react';
import cn from 'classnames';
import CareerHero from './CareerHero/CareerHero';
import PerkSection from './PerkSection/PerkSection';
import PeopleSection from './PeopleSection/PeopleSection';
import OurTeams from './TeamSection/TeamSection';
import GrowthSection from './GrowthSection/GrowthSection';
import css from './index.scss';
import Footer from 'styleguide/components/Footer/Footer';
import Grid from 'styleguide/components/Grid/Grid';
import Meta from 'styleguide/components/Meta/Meta';

import footerImage from 'assets/images/CareerPage/careers-footer.jpg';

const CareersPage = () => (
  <>
    <Meta
      title="Careers | Printivity"
      description="Looking for something more than another paycheck? Achieve new levels of professional and personal betterment with a career at Printivity."
      canonical="https://www.printivity.com/careers"
    />
    <CareerHero />
    <PerkSection />
    <GrowthSection />
    <PeopleSection />
    <OurTeams />
    <Grid.Container>
      <Grid fullWidth>
        <Grid.Row>
          <Grid.Col className={cn(css.imageContainer)} style={{ backgroundImage: `url('${footerImage}')` }} />
        </Grid.Row>
      </Grid>
    </Grid.Container>
    <Footer />
  </>
);

export default CareersPage;
